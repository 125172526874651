import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../../Components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../Database/config";
import { dbc, storageRefc } from "../../Database/fundi";
import countiesData from "../../data/county.json";
import { Toast } from "primereact/toast";
import firebase from "firebase/app";
import "firebase/firestore";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export default function FundiUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useRef(null);

  const [data, setData] = useState({});
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [profession, setProfession] = useState("");
  const [professionalbody, setProfessionalBody] = useState("");
  const [professionalbodyno, setProfessionalBodyNo] = useState("");
  const [identificationnumber, setIdentificationNumber] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [countylocation, setCountyLocation] = useState("");
  const [goodconductrefno, setGoodConductRefNo] = useState("");
  const [subcounty, setSubcounty] = useState("");
  const [filteredConstituencies, setFilteredConstituencies] = useState([]);
  const [profileimage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profileUrl, setProfileUrl] = useState("");
  const [idFront, setIdFront] = useState("");
  const [idFrontUrl, setIdFrontUrl] = useState("");
  const [idBack, setIdBack] = useState("");
  const [idBackUrl, setIdBackUrl] = useState("");
  const [cv, setCv] = useState("");
  const [cvUrl, setCvUrl] = useState("");
  const [goodConduct, setGoodConduct] = useState("");
  const [goodConductUrl, setGoodConductUrl] = useState("");
  const [nca, setNca] = useState("");
  const [ncaUrl, setNcaUrl] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [professionError, setProfessionError] = useState(false);
  const [subcountyError, setSubcountyError] = useState(false);
  const [countyLocationError, setCountyLocationError] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    const fetchUserData = async () => {
      try {
        const docSnapshot = await dbc.collection("FundiAppUsers").doc(id).get();

        if (docSnapshot.exists) {
          const userData = docSnapshot.data();
          if (userData.countylocation) {
            const selectedCountyData = countiesData.County.find(
              (county) => county.county_name === userData.countylocation
            ).constituencies;

            setFilteredConstituencies(selectedCountyData);
          }
          setData(userData);
          setFirstName(userData.firstname || "");
          setLastName(userData.lastname || "");
          setProfession(userData.profession || "");
          setProfessionalBody(userData.professionalbody || "");
          setProfessionalBodyNo(userData.professionalbodyno || "");
          setIdentificationNumber(userData.identificationnumber || "");
          setPhoneNumber(userData.phonenumber || "");
          setProfileUrl(userData.profileimage || "");
          setIdFrontUrl(userData.idFront || "");
          setIdBackUrl(userData.idBack || "");
          setGoodConductUrl(userData.goodConduct || "");
          setCvUrl(userData.cv || "");
          setNcaUrl(userData.nca || "");
          setEmail(userData.email || "");
          setCountyLocation(userData.countylocation || "");
          setSubcounty(userData.subcounty || "");
          setGoodConductRefNo(userData.goodconductrefno || "");
        } else {
          alert("Error fetching user data! Try again later.");
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchUserData();
  }, [id, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleIdFrontFileChange = (e) => {
    const file = e.target.files[0];
    setIdFront(file);
  };

  const handleIdBackFileChange = (e) => {
    const file = e.target.files[0];
    setIdBack(file);
  };

  const handleCvFileChange = (e) => {
    const file = e.target.files[0];
    setCv(file);
  };

  const handleGoodConductFileChange = (e) => {
    const file = e.target.files[0];
    setGoodConduct(file);
  };

  const handleNCAFileChange = (e) => {
    const file = e.target.files[0];
    setNca(file);
  };

  const uploadImageToStorage = async () => {
    if (profileimage) {
      const imageRef = storageRefc.child(`profileImages/${profileimage.name}`);
      await imageRef.put(profileimage);
      const imageUrl = await imageRef.getDownloadURL();
      return imageUrl;
    }
    return null;
  };

  const uploadIdFront = async () => {
    if (idFront) {
      const imageRef = storageRefc.child(`FrontID/${idFront.name}`);
      await imageRef.put(idFront);
      const idFrontUrl = await imageRef.getDownloadURL();
      return idFrontUrl;
    }
    return null;
  };

  const uploadIdBack = async () => {
    if (idBack) {
      const imageRef = storageRefc.child(`BackID/${idBack.name}`);
      await imageRef.put(idBack);
      const idBackUrl = await imageRef.getDownloadURL();
      return idBackUrl;
    }
    return null;
  };

  const uploadCV = async () => {
    if (cv) {
      const imageRef = storageRefc.child(`CV/${cv.name}`);
      await imageRef.put(cv);
      const cvUrl = await imageRef.getDownloadURL();
      return cvUrl;
    }
    return null;
  };

  const uploadGoodConduct = async () => {
    if (goodConduct) {
      const imageRef = storageRefc.child(`GoodConduct/${goodConduct.name}`);
      await imageRef.put(goodConduct);
      const goodConductUrl = await imageRef.getDownloadURL();
      return goodConductUrl;
    }
    return null;
  };

  const uploadNCA = async () => {
    if (nca) {
      const imageRef = storageRefc.child(`NCA/${nca.name}`);
      await imageRef.put(nca);
      const ncaUrl = await imageRef.getDownloadURL();
      return ncaUrl;
    }
    return null;
  };


  function handleCountyChange(event) {
    const selectedCounty = event.target.value;
    setCountyLocation(selectedCounty);

    const selectedCountyData = countiesData.County.find(
      (county) => county.county_name === selectedCounty
    ).constituencies;

    setFilteredConstituencies(selectedCountyData);
    setSubcounty("");
  }

  const handleSubCountyChange = (e) => {
    setSubcounty(e.target.value);
  };

  const handleUpdate = async () => {
    if (firstname === "") {
      setFirstNameError(true);
      return;
    }

    if (lastname === "") {
      setLastNameError(true);
      return;
    }

    if (identificationnumber === "") {
      setIdError(true);
      return;
    }

    if (email === "") {
      setEmailError(true);
      return;
    }

    if (!phonenumber.match(/^(07|01)\d{8}$/)) {
      setPhoneError(true);
      return;
    }

    if (countylocation === null) {
      setCountyLocationError(true);
      return;
    }

    if (subcounty === null) {
      setSubcountyError(true);
      return;
    }

    if (profession === "") {
      setProfessionError(true);
      return;
    }

    if (
      firstname &&
      lastname &&
      email &&
      profession &&
      phonenumber &&
      countylocation &&
      subcounty
    ) {
      setIsLoading(true);

      const imageUrl = await uploadImageToStorage();
      const idFront = await uploadIdFront();
      const idBack = await uploadIdBack();
      const cv = await uploadCV();
      const goodConduct = await uploadGoodConduct();
      const nca = await uploadNCA();

      try {
        await dbc
          .collection("FundiAppUsers")
          .doc(id)
          .update({
            firstname,
            lastname,
            profession,
            professionalbody,
            professionalbodyno,
            identificationnumber,
            phonenumber,
            email,
            countylocation,
            subcounty,
            goodconductrefno,
            profileimage: imageUrl ? imageUrl : profileUrl,
            cv: cv ? cv : cvUrl,
            idFront: idFront ? idFront : idFrontUrl,
            idBack: idBack ? idBack : idBackUrl,
            goodConduct: goodConduct ? goodConduct : goodConductUrl,
            nca: nca ? nca : ncaUrl,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: auth.currentUser.uid
          });

        toast.current.show({
          severity: "success",
          summary: "User Updated",
          detail: "User updated successfully",
          life: 3000,
        });

        setIsLoading(false);

        return setTimeout(() => {
          navigate(`/fundi/${id}`);
        }, 3000);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <MainHeader />
      <Toast ref={toast} position="bottom-left" className="text-sm" />
      <div className="flex sm:flex-nowrap flex-wrap justify-between py-12 gap-4 sm:px-12 px-6">
        <div className="flex flex-col gap-2">
          {data.profileimage && (
            <img
              src={data.profileimage}
              alt="Profile Image"
              className="w-48 h-auto rounded"
            ></img>
          )}
          {data.subscription != null && (
            <div className="flex flex-col gap-2">
              <h1 className="text-center text-lg font-bold">
                <span className="text-gray-600 uppercase">{`${data.firstname} ${data.lastname}`}</span>
              </h1>
              {data.subscription ? (
                <div className="bg-green-700 uppercase text-xs text-center rounded text-white py-2 font-semibold">
                  Active Subscription
                </div>
              ) : (
                <div className="bg-red-700 uppercase text-xs text-center rounded text-white py-2 font-semibold">
                  Inactive Subscription
                </div>
              )}
            </div>
          )}
        </div>
        <div className="sm:w-5/6 flex flex-col items-end gap-4 rounded-lg shadow-lg bg-white border border-gray-200 py-6 sm:px-12 px-6">
          <div className="grid sm:grid-cols-3 grid-cols-1 justify-start items-start gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">First Name:</label>
              <input
                className="p-2 rounded border border-gray-400"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {firstNameError && (
                <div className="ErrorMessage">First Name Required</div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">Last Name:</label>
              <input
                className="p-2 rounded border border-gray-400"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
              />
              {lastNameError && (
                <div className="ErrorMessage">Last Name Required</div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">ID Number:</label>
              <input
                className="p-2 rounded border border-gray-400"
                value={identificationnumber}
                onChange={(e) => setIdentificationNumber(e.target.value)}
              />
              {idError && (
                <div className="ErrorMessage">National ID is required</div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">Email:</label>
              <input
                className="p-2 rounded border border-gray-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <div className="ErrorMessage">Email is required</div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">Profession:</label>
              <select
                className="p-2 rounded border border-gray-400"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                required
              >
                <option value="">-- Select --</option>
                <option>Mason</option>
                <option>Water Tank Expert</option>
                <option>Key Cutting, Locks and Doors</option>
                <option>Interior Designer</option>
                <option>General Builder(Cost Estimator)</option>
                <option>Painter</option>
                <option>Household Electronics Expert</option>
                <option>Fumigation Expert</option>
                <option>Electrician and Electrical fittings</option>
                <option>Plumber and Sanitary Fittings</option>
                <option>Glass/ Mirror/ Window fixer</option>
                <option>Aluminium and Fit-outs</option>
                <option>Carpenter</option>
                <option>Tiles - Ceramic, Granite</option>
                <option>Roofer</option>
                <option>Mazeras/ Cladding</option>
                <option>Metal Expert - Welder</option>
                <option>Locks and Doors</option>
                <option>Waterproofing Expert</option>
                <option>External Paver</option>
                <option>Refrigeration Services</option>
                <option>Terrazzo</option>
                <option>Landscaper</option>
                <option>Parking Shades</option>
                <option>Window Blinds, Curtains, Boxes, Rods, Films and Tints</option>
                <option>Advertising Signages and Boxes</option>
                <option>Carpet and Upholstery</option>
                <option>Furniture and Fittings</option>
                <option>Plastic Pipes and Fittings</option>
                <option>Concrete Pipes and Culverts</option>
                <option>Gypsum and Ceiling Expert</option>
              </select>
              {professionError && (
                <div className="ErrorMessage">Profession required</div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">Profession Body:</label>
              <select
                className="p-2 rounded border border-gray-400"
                value={professionalbody}
                onChange={(e) => setProfessionalBody(e.target.value)}
                required
              >
                <option value="">-- Select --</option>
                <option>
                  Police Clearance Certificate: Registered Public Servant
                </option>
                <option>NCA: Registered Construction Professional</option>
                <option>KMPDC: Registered Medical Practitioner</option>
                <option>NCK: Nurses Council Kenya</option>
                <option>EARB: Estate Agent</option>
                <option>ISK: Estate Agent</option>
                <option>VRB: Estate Agent</option>
                <option>ERB: Engineers</option>
                <option>BORAQS: Architect/ Quantity Surveyor</option>
                <option>KBCTFIEU: Registered Construction Union</option>
                <option>KNFJKA: Jua Kali Workers</option>
              </select>
              {false && <div className="ErrorMessage"></div>}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">
                Professional Body Registration No.:
              </label>
              <input
                className="p-2 rounded border border-gray-400"
                value={professionalbodyno}
                onChange={(e) => setProfessionalBodyNo(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">
                Primary Mpesa Phone Number:
              </label>
              <input
                className="p-2 rounded border border-gray-400"
                value={phonenumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {phoneError && (
                <div className="ErrorMessage">Enter a valid phone number</div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">
                Good Conduct Ref. Number:
              </label>
              <input
                className="p-2 rounded border border-gray-400 uppercase"
                value={goodconductrefno}
                onChange={(e) => setGoodConductRefNo(e.target.value)}
              />
              {false && <div className="ErrorMessage"></div>}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">County:</label>
              <select
                className="p-2 rounded border border-gray-400"
                value={countylocation}
                onChange={handleCountyChange}
              >
                <option value="">Select a county</option>
                {countiesData.County.map((county, index) => (
                  <option key={index} value={county.county_name}>
                    {county.county_name}
                  </option>
                ))}
              </select>
              {countyLocationError && (
                <div className="ErrorMessage">Select county location</div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium">Sub County</label>
              <select
                className="p-2 rounded border border-gray-400"
                value={subcounty}
                onChange={handleSubCountyChange}
              >
                <option value="">Select Sub County</option>
                {filteredConstituencies.map((constituency) => (
                  <option
                    key={constituency.constituency_name}
                    value={constituency.constituency_name}
                  >
                    {constituency.constituency_name}
                  </option>
                ))}
              </select>
              {subcountyError && (
                <div className="text-xs text-red-700 error">
                  Sub County Required
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">
                Upload New Profile Picture
              </label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleFileChange}
                accept="image/png, image/jpeg"
                placeholder="Upload Picture"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">
                Upload Front Copy of ID
              </label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleIdFrontFileChange}
                accept="image/png, image/jpeg"
                placeholder="Upload Picture"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">
                Upload Back Copy of ID
              </label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleIdBackFileChange}
                accept="image/png, image/jpeg"
                placeholder="Upload Picture"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">Upload Copy of CV</label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleCvFileChange}
                accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="Upload Picture"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">
                Upload Good Conduct
              </label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleGoodConductFileChange}
                accept="image/png, image/jpeg,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="Upload Picture"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-semibold">
                Upload NCA Registration Certificate
              </label>
              <InputText
                className="border border-gray-200 px-2 py-2 rounded capitalize"
                type="file"
                onChange={handleNCAFileChange}
                accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                placeholder="Upload Picture"
              />
            </div>
          </div>

          <div className="bg-gray-900 rounded hover:bg-red-600 text-white font-semibold px-4 py-2">
            <Button
              label="Update User"
              icon="pi pi-check"
              loading={isLoading}
              onClick={handleUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
