import React, { useEffect } from 'react'
import Fundi from '../../Assets/fundi.png'
import './HomeScreen.css'
import MainHeader from '../../Components/Header/Header'
import { auth } from '../../Database/config'
import { Link, useNavigate } from 'react-router-dom'

export default function HomeScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })
    return unsubscribe
  }, []);

  return (
    <div className='flex flex-col'>
      <MainHeader />
      <div className='py-6 flex flex-col'>

        <div className='flex flex-row items-center justify-center sm:mb-0 mb-8 gap-12'>

          <img className='sm:flex hidden w-48 h-auto' src={Fundi} alt='Fundi' />

          <div className='flex flex-col gap-2'>
            <h1 className='text-2xl font-bold text-[#17304A]'>Welcome to Rekebisha Admin portal</h1>
            <p className='font-medium text-red-600'>Here you can do the following Functions:</p>
            <ul className='ml-4 list-decimal text-sm space-y-1'>
              <li>Register (Add) and artisan or a fundi</li>
              <li>Register an Agent</li>
              <li>Check Orders</li>
              <li>Check Pending or Completed Tasks</li>
              <li>Manage Agents/Fundis</li>
            </ul>
          </div>
        </div>
        <div className='sm:px-12 px-6 flex flex-wrap items-center justify-between shadow w-full bg-gray-100 py-6'>
          <Link to='/fundi' style={{ textDecoration: "none", color: 'black', fontSize: '15' }}>
            <div className='appCard flex flex-col gap-4 py-2'>
              <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
              <h2 className='appLabel'>
                Fundi
              </h2>
            </div>
          </Link>
          <Link to='/hardware' style={{ textDecoration: "none", color: 'black', fontSize: '15' }}>
            <div className='appCard flex flex-col gap-4 py-2'>
              <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
              <h2 className='appLabel'> Hardware</h2>
            </div>
          </Link>
          <Link to='/clients' className='appCard flex flex-col gap-4 py-2'>
            <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
            <h2 className='appLabel'> Clients</h2>
          </Link>
          <Link to='#' className='appCard flex flex-col gap-4 py-2'>
            <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
            <h2 className='appLabel'> DotDot</h2>
          </Link>
          <Link to='#' className='appCard flex flex-col gap-4 py-2'>
            <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
            <h2 className='appLabel'> Find Property</h2>
          </Link>
        </div>

      </div>
    </div>
  );
}
