import { authd, dbd } from "./dotdot"
import { dbc,authc, storagec, storageRefc } from "./fundi"
import { authm, dbm } from "./materials"
import { authr, dbr } from "./rental"
import ImageResizer from 'browser-image-resizer';


export const createFundiDocument = async (firstname, lastname, phonenumber, identificationnumber, professionalbodyno,
    profession, countylocation, goodconductrefno, profileimage) => {

    try {
        // Step 1: Upload the picture to Firebase Storage
        const pictureRef = storageRefc.child(`profilePictures/${authc.currentUser.uid}`);
        await pictureRef.put(profileimage);

        // Step 2: Get the download URL of the uploaded picture
        const pictureURL = await pictureRef.getDownloadURL();

        // Step 3: Update Firestore document with the picture URL
        await dbc.collection('FundiAppUsers').doc(authc.currentUser.uid).set({
            firstname,
            lastname,
            phonenumber,
            identificationnumber,
            professionalbodyno,
            profession,
            countylocation,
            goodconductrefno,
            pictureURL // Add the picture URL to the Firestore document
        });

        console.log("Document created successfully with profile image URL:", pictureURL);
        return pictureURL; // You can return the picture URL if needed

    } catch (error) {
        console.error("Error creating Fundi document:", error);
        throw error;
    }
}

export const createMaterialDocument = (businessname, ownersname, businessregistrationnumber, phonenumber, 
    idnumber, category,  wphonenumber,latitude, longitude, subcounties, selectedCounty) => {
    return dbm.collection('Business').doc(authm.currentUser.uid).set({
        businessname, 
        ownersname, 
        businessregistrationnumber, 
        phonenumber, 
        idnumber, 
        category, 
        wphonenumber,
        latitude, 
        longitude, 
        subcounties, 
        selectedCounty
    })
}

export const createRentalDocument = (businessname, ownersname, businessregistrationnumber, phonenumber, 
    idnumber, category,  wphonenumber,latitude, longitude, subcounties, selectedCounty) => {
    return dbr.collection('RealEstate').doc(authr.currentUser.uid).set({
        businessname, 
        ownersname, 
        businessregistrationnumber, 
        phonenumber, 
        idnumber, 
        category, 
        wphonenumber,
        latitude, 
        longitude, 
        subcounties, 
        selectedCounty
    })
}

export const createDotDotDocument = (firstname, lastname,category, dlNumber, vrn, vehicleModel, idNumber) => {
    return dbd.collection('DotDotUSers').doc(authd.currentUser.uid).set({
        firstname,
        lastname,
        category, 
        dlNumber, 
        vrn, 
        vehicleModel, 
        idNumber
    })
}