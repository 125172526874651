import { html2pdf } from 'html2pdf.js';
import 'firebase/database';

const Receipt = ( ) => {

  const generatePDF = () => {
    const element = document.getElementById('receipt-container');
    html2pdf(element);
  };

  return (
    <div id="receipt-container">
      <h2>Receipt</h2>
      <div>
        <strong>Order Number:</strong> 
      </div>
      <div>
        <strong>Date:</strong> 
      </div>
      <div>
        <strong>Items:</strong>
        <ul>
          
        </ul>
      </div>
      <div>
        <strong>Total:</strong> 
      </div>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default Receipt;
