import MainHeader from "../../Components/Header/Header";
import { InformationCircleIcon } from '@heroicons/react/16/solid';

export default function Payments() {
    return (
        <div className="flex flex-col">
            <MainHeader className="mb-20" />
            <div className="flex justify-center gap-4 flex-col items-center py-40">
                <InformationCircleIcon className="text-gray-200 w-32 h-32"/>
                <div className="text-xl font-semibold text-gray-500">System Under Construction</div>
            </div>
        </div>
    );
}