import React, { useState, useRef, useEffect } from "react";
import MainHeader from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Database/config";
import countiesData from "../../data/county.json";
import { authc, dbc, storageRefc } from "../../Database/fundi";
import { InputText } from "primereact/inputtext";
import firebase from "firebase/app";
import "firebase/firestore";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";


export default function CreateFundi() {
  const navigate = useNavigate();
  const toast = useRef(null);

  const [loading, setIsLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [profession, setProfession] = useState("");
  const [email, setEmail] = useState("");
  const [professionalbody, setProfessionalBody] = useState("");
  const [professionalbodyno, setProfessionalBodyNo] = useState("");
  const [identificationnumber, setIdentificationNumber] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [goodconductref, setGoodConductRefNumber] = useState("");
  const [profileimage, setProfileImage] = useState(null);
  const [countylocation, setCountyLocation] = useState("");
  const [counties, setCounties] = useState([]);
  const [subcounty, setSubcounty] = useState("");
  const [filteredConstituencies, setFilteredConstituencies] = useState([]);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [professionError, setProfessionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [professionalBodyError, setProfessionalBodyError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [profileImageError, setProfileImageError] = useState(false);
  const [countyError, setCountyError] = useState(false);
  const [subcountyError, setSubcountyError] = useState(false);
  const [idError, setIdError] = useState(false);

  useEffect(() => {
    setCounties(countiesData.County);

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });
    return unsubscribe;
  }, []);

  function handleCountyChange(event) {
    setSubcounty("");
    const selectedCounty = event.target.value;
    setCountyLocation(selectedCounty);

    const selectedCountyData = countiesData.County.find(
      (county) => county.county_name === selectedCounty
    ).constituencies;

    setFilteredConstituencies(selectedCountyData);
  }

  const handleSubCountyChange = (e) => {
    setSubcounty(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const uploadImageToStorage = async () => {
    if (profileimage) {
      const imageRef = storageRefc.child(`profileImages/${profileimage.name}`);
      await imageRef.put(profileimage);
      const imageUrl = await imageRef.getDownloadURL();
      return imageUrl;
    }
    return null;
  };

  const validateData = () => {
    if (!firstname) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }

    if (!lastname) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (!email) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (!profession) {
      setProfessionError(true);
    } else {
      setProfessionError(false);
    }

    if (!professionalbody) {
      setProfessionalBodyError(true);
    } else {
      setProfessionalBodyError(false);
    }

    if (!phonenumber) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }

    if (!identificationnumber) {
      setIdError(true);
    } else {
      setIdError(false);
    }

    if (!countylocation) {
      setCountyError(true);
    } else {
      setCountyError(false);
    }

    if (!subcounty) {
      setSubcountyError(true);
    } else {
      setSubcountyError(false);
    }

    if (!goodconductref) {
      setGoodConductRefNumber("Not Available");
    }

    if (profileimage == null) {
      setProfileImageError(true);
    } else {
      setProfileImageError(false);
    }
  };

  const handleRegistration = async () => {
    validateData();
    if (
      !firstname ||
      !lastname ||
      !email ||
      !profession ||
      !professionalbody ||
      !phonenumber ||
      !identificationnumber ||
      !countylocation ||
      !subcounty ||
      !goodconductref ||
      profileimage == null
    ) {
      return;
    }

    setIsLoading(true);

    const imageUrl = await uploadImageToStorage();

    // Register to Rekebisha Admin website
    authc
      .createUserWithEmailAndPassword(email, identificationnumber)
      .then((userCredentials) => {
        const user = userCredentials.user;
        dbc.collection("FundiAppUsers").doc(user.uid).set({
          firstname,
          lastname,
          professionalbody,
          profession,
          professionalbodyno,
          identificationnumber,
          phonenumber,
          email,
          countylocation,
          subcounty,
          goodconductref,
          profileimage: imageUrl,
          subscription: true,
          registeredBy: auth.currentUser.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        toast.current.show({
          severity: "success",
          summary: "Fundi Created",
          detail: "Fundi created successfully",
          life: 3000,
        });
        setIsLoading(false);
        return setTimeout(() => {
          navigate("/fundi");
        }, 1000);
      })
      .catch((error) =>
        toast.current.show({
          severity: "error",
          summary: "Registration Failed",
          detail: error,
          life: 3000,
        })
      );
  };

  const cancelRegistration = () => {
    navigate("/fundi");
  };

  return (
    <div className="">
      <Toast ref={toast} position="bottom-left"></Toast>
      <MainHeader />
      <div className="flex gap-2 w-full justify-center text-2xl capitalize font-bold py-6">
        <span className="text-red-600"> R-Fundi</span> Account Registration
      </div>
      <div className="bg-gray-100 px-12 py-6 w-full">
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-4">
          <div className="flex flex-col gap-1">
            <label className="text-sm">First Name</label>
            <InputText
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              placeholder="First Name"
              autoFocus
              keyfilter="alpha"
              required
            />
            {firstNameError && (
              <div className="text-xs text-red-700 error">
                *First Name Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Last Name</label>
            <InputText
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              keyfilter="alpha"
              placeholder="Last Name"
              required
            />
            {lastNameError && (
              <div className="text-xs text-red-700 error">
                *Last Name Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Email</label>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded lowercase"
              keyfilter="email"
              required
            />
            {emailError && (
              <div className="text-xs text-red-700 error">
                *Email Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Profession</label>
            <select
              className="border border-gray-200 px-2 py-2 rounded"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
              required
            >
              <option value="">-- Select --</option>
              <option>Mason</option>
              <option>Water Tank Expert</option>
              <option>Key Cutting, Locks and Doors</option>
              <option>Interior Designer</option>
              <option>General Builder(Cost Estimator)</option>
              <option>Painter</option>
              <option>Household Electronics Expert</option>
              <option>Fumigation Expert</option>
              <option>Electrician and Electrical fittings</option>
              <option>Plumber and Sanitary Fittings</option>
              <option>Glass/ Mirror/ Window fixer</option>
              <option>Aluminium and Fit-outs</option>
              <option>Carpenter</option>
              <option>Tiles - Ceramic, Granite</option>
              <option>Roofer</option>
              <option>Mazeras/ Cladding</option>
              <option>Metal Expert - Welder</option>
              <option>Locks and Doors</option>
              <option>Waterproofing Expert</option>
              <option>External Paver</option>
              <option>Refrigeration Services</option>
              <option>Terrazzo</option>
              <option>Landscaper</option>
              <option>Parking Shades</option>
              <option>Window Blinds, Curtains, Boxes, Rods, Films and Tints</option>
              <option>Advertising Signages and Boxes</option>
              <option>Carpet and Upholstery</option>
              <option>Furniture and Fittings</option>
              <option>Plastic Pipes and Fittings</option>
              <option>Concrete Pipes and Culverts</option>
              <option>Gypsum and Ceiling Expert</option>
            </select>
            {professionError && (
              <div className="text-xs text-red-700 error">
                *Profession Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Profession Body</label>
            <select
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              value={professionalbody}
              onChange={(e) => setProfessionalBody(e.target.value)}
              required
            >
              <option value="">-- Select --</option>
              <option>
                Police Clearance Certificate: Registered Public Servant
              </option>
              <option>NCA: Registered Construction Professional</option>
              <option>KMPDC: Registered Medical Practitioner</option>
              <option>NCK: Nurses Council Kenya</option>
              <option>EARB: Estate Agent</option>
              <option>ISK: Estate Agent</option>
              <option>VRB: Estate Agent</option>
              <option>ERB: Engineers</option>
              <option>BORAQS: Architect/ Quantity Surveyor</option>
              <option>KBCTFIEU: Registered Construction Union</option>
              <option>KNFJKA: Jua Kali Workers</option>
            </select>
            {professionalBodyError && (
              <div className="text-xs text-red-700 error">
                *Professional Body Required
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-sm">
              Profession Body Registration Number
            </label>
            <InputText
              value={professionalbodyno}
              onChange={(e) => setProfessionalBodyNo(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              type="text"
              placeholder="Profession Body Registration Number"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">County Location</label>
            <select
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              value={countylocation}
              onChange={handleCountyChange}
              required
            >
              <option value="">-- Select County --</option>
              {counties.map((county, index) => (
                <option key={index} value={county.county_name}>
                  {county.county_name}
                </option>
              ))}
            </select>
            {countyError && (
              <div className="text-xs text-red-700 error">
                *County Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Sub County</label>
            <select
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              value={subcounty}
              onChange={handleSubCountyChange}
              required
            >
              <option value="">-- Select Sub County --</option>
              {filteredConstituencies.map((constituency) => (
                <option
                  key={constituency.constituency_name}
                  value={constituency.constituency_name}
                >
                  {constituency.constituency_name}
                </option>
              ))}
            </select>
            {subcountyError && (
              <div className="text-xs text-red-700 error">
                *Sub County Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Primary Mpesa Number</label>
            <InputText
              value={phonenumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              type="text"
              keyfilter="int"
              placeholder="Phone Number"
            />
            {phoneNumberError && (
              <div className="text-xs text-red-700 error">
                *Phone Number Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">National ID</label>
            <InputText
              value={identificationnumber}
              onChange={(e) => setIdentificationNumber(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              type="text"
              keyfilter="int"
              placeholder="National ID Number"
            />
            {idError && (
              <div className="text-xs text-red-700 error">
                *National ID Required
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Good Conduct Reference Number</label>
            <InputText
              value={goodconductref}
              onChange={(e) => setGoodConductRefNumber(e.target.value)}
              className="border border-gray-200 px-2 py-2 rounded uppercase"
              type="text"
              placeholder=""
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Upload Picture</label>
            <InputText
              className="border border-gray-200 px-2 py-2 rounded capitalize"
              type="file"
              onChange={handleFileChange}
              accept="image/png, image/jpeg"
              placeholder="Upload Picture"
            />
            {profileImageError && (
              <div className="text-xs text-red-700 error">
                *Profile Picture Required
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between px-12 mt-6">
        <button
          disabled={loading}
          onClick={cancelRegistration}
          className="bg-gray-400 justify-end flex text-white rounded px-4 py-3 font-medium gap-2"
        >
          Cancel
        </button>
        <div className="bg-black justify-end flex text-white rounded px-4 py-3 font-medium gap-2">
          <Button
            label="Register R-Fundi Agent"
            icon="pi pi-check"
            loading={loading}
            onClick={handleRegistration}
          />
        </div>
      </div>
    </div>
  );
}
