import React, { useEffect, useState } from 'react'
import '../DotDotSignUp/DotDotsignup.css'
import MainHeader from '../../Components/Header/Header'
import { auth } from '../../Database/config'
import { useNavigate } from 'react-router-dom'
import { authm } from '../../Database/materials'
import { createMaterialDocument } from '../../Database/dbmethods'
import countiesData from '../../data/county.json'; 

export default function MaterialSignup() {
    const navigate = useNavigate();

    const [businessname, setBusinessName] = useState("");
    const [ownersname, setOwnersName] = useState("");
    const [category, setCategory] = useState([]);
    const [businessregistrationnumber, setBusinessRegistrationNumber] = useState("");
    const [email, setEmail] = useState("");
    const [idnumber, setIdNumber] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [counties, setCounties] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [wphonenumber, setWPhoneNumber] = useState("");
    const [subcounties, setSubCounties] = useState("");
    const [selectedCounty, setSelectedCounty] = useState('');
    const [filteredConstituencies, setFilteredConstituencies] = useState([]);

    const handleCountyChange = (event) => {
      const selectedCounty = event.target.value;
      setSelectedCounty(selectedCounty);
    
      // Use the selected county to filter the constituencies
      const filteredConstituencies = countiesData.County.find(
        (county) => county.county_name === selectedCounty
      ).constituencies;
    
      // Set the filtered constituencies in state
      setFilteredConstituencies(filteredConstituencies);
    };
    



//Getting the location of the computer
    useEffect(() => {
      const getLocation = async () => {
        try {
          if (navigator.geolocation) {
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });
  
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
  
          } else {
            console.log('Geolocation is not supported by this browser.');
          }
        } catch (error) {
          console.error('Error getting location:', error);
        }
      };
  
      getLocation();
    }, []);


    // const county = countiesData.County.map((option) => (
    //     <option key={option.county_id} value={option.county_name}>
    //       {option.county_name}
    //     </option>
    //   ));

    // const constituencies = countiesData.County.map((option) => (
    //     option.constituencies.map((constituency) => (
    //         <option key={constituency.constituency_id} value={constituency.constituency_name}>
    //       {constituency.constituency_name}
    //     </option>
    //     ))  
    //   ));


    useEffect(() => {
        // Load counties from the imported JSON data
        setCounties(countiesData.County);
      }, []);
    
     
      const handleSubCountyChange = (event) => {
        setSubCounties(event.target.value);
      };



    const handleSignUp = () => {

        if (
            email !== '' &&
            businessname !== '' &&
            phonenumber !== '' &&
            businessregistrationnumber !== '' &&
            idnumber !== '' &&
            ownersname !== '' &&
            category !=='' &&
            wphonenumber !=='' &&
            longitude !=='' &&
            latitude !=="" &&
            counties !=="" &&
            subcounties !=="" 
        ){
            authm
            .createUserWithEmailAndPassword(email, idnumber)
            .then(userCredentials => {
             const user = userCredentials.user;
             createMaterialDocument(businessname, ownersname, businessregistrationnumber, phonenumber, 
                idnumber, category, wphonenumber,latitude, longitude, subcounties, selectedCounty );
             console.log('Registered in with:', user.email);

            })
            .catch(error => alert(error.message))

            //navigation to download the app
            alert("Business registered succesfully");
            navigate('/homescreen');
        }else{
         alert("Please fill all the fields !!");
        }
       
     }


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/')
            }
        })
      
        return unsubscribe
      }, [])
  return (
    <div className='DotSContainer'>
        <MainHeader/>
        <div className='DotSbody'>
            <div className='DotScard'>
                <h1 className='DotH1'>Let’s get you started</h1>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='Business Name' 
                    onChange={(e)=>setBusinessName(e.target.value)} 
                    value={businessname}
                    />
                    <input className='DotDotInput'
                     placeholder='Nominated Owners Full Name '
                      value={ownersname}
                      onChange={(e)=>setOwnersName(e.target.value)} 
                     />
                </div>
                <div className='DotInputs'>
                    <select className='DotDotInput' 
                    placeholder=''
                    value={category}                    
                    onChange={(e) => setCategory(Array.from(e.target.selectedOptions, (option) => option.value))}
                    multiple
                    >
                         <option value="">Select a Category</option>
                         <option>LPG Cooking Gas</option>
                         <option>Key Cutting, Locks and Doors</option>
                         <option>Interior Design Art Store</option>
                         <option>General Hardware</option>
                          <option>Electrical Hardware</option>
                          <option>Plastic Pipes and Fittings Hardware</option>
                          <option>Concrete Pipes and Culverts</option>
                          <option>Tiles and Sanitary Fittings Hardware</option>
                          <option>Paints and Cement Hardware</option>
                          <option>General Metal Hardware</option>
                          <option>Locks and Doors Hardware</option>
                          <option>Household Electronics Hardware</option>
                          <option>Agrovet/ Fumigation Expert</option>
                          <option>Glass/ Mirror/ Window Hardware</option>
                          <option>Aluminium and Fit-outs Hardware</option>
                          <option>Timber and Gypsum Hardware</option>
                          <option>Roofing Hardware</option>
                          <option>Mazeras/ Cladding Hardware</option>
                          <option>External Paver Hardware</option>
                          <option>Window Blinds, Curtains, Boxes, Rods, Films and Tints</option>
                          <option>Advertising Signages and Boxes</option>
                          <option>Carpet and Upholstery</option>
                          <option>Furniture and Fittings</option>
                    </select>
                    <input className='DotDotInput' 
                    placeholder='Business Registration Number' 
                    value={businessregistrationnumber}
                    onChange={(e)=>setBusinessRegistrationNumber(e.target.value)} 
                    />
                </div>
                <div className='DotInputs'>
                <select
                    className='DotDotInput'
                    placeholder='Location'
                    value={selectedCounty}
                    onChange={handleCountyChange}
                  >
                    <option value=''>-- Select County --</option>
                    {countiesData.County.map((county) => (
                      <option key={county.county_name} value={county.county_name}>
                        {county.county_name}
                      </option>
                    ))}
                  </select>

                    <input className='DotDotInput' 
                    placeholder='Primary Phone Number(Mpesa)' 
                    value={phonenumber}
                    onChange={(e)=>setPhoneNumber(e.target.value)} 
                    />
                </div>
                <div className='DotInputs'>
                <select
                      className='DotDotInput'
                      placeholder='Location'
                      value={subcounties}
                      onChange={handleSubCountyChange}
                    >
                      <option value=''>-- Select Sub-County --</option>
                      {filteredConstituencies.map((constituency) => (
                        <option key={constituency.constituency_name} value={constituency.constituency_name}>
                          {constituency.constituency_name}
                        </option>
                      ))}
                    </select>

                    <input className='DotDotInput' 
                    placeholder=' WhatsApp Phone Number(+2547***)' 
                    value={wphonenumber}
                    onChange={(e)=>setWPhoneNumber(e.target.value)} 
                    />
                </div>
                
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='ID Number' 
                    value={idnumber}
                    onChange={(e)=>setIdNumber(e.target.value)} 
                    />
                    <input className='DotDotInput' 
                    placeholder='Email' 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)} 
                    />
                </div>
               
                <button onClick={handleSignUp} className='DotDotRegister'>Register</button>
            </div>
        </div>
    </div>
  )
}
