import React, { useEffect, useState } from 'react'
import './DotDotsignup.css'
import MainHeader from '../../Components/Header/Header'
import { auth } from '../../Database/config'
import { useNavigate } from 'react-router-dom'
import { authd } from '../../Database/dotdot'
import { createDotDotDocument } from '../../Database/dbmethods'

export default function DotDotsignup() {
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [category, setCategory] = useState("");
    const [dlNumber, setDlNumber] = useState("")
    const [vrn, setVrn] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [agentcode, setAgentCode] = useState("")
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [password, setpassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    const handleSignUp = () => {
            authd
        .createUserWithEmailAndPassword(email, password)
        .then(userCredentials => {
         const user = userCredentials.user;
         createDotDotDocument(firstname, lastname,category, dlNumber, vrn, vehicleModel, idNumber, agentcode  );
         console.log('Registered in with:', user.email);
        })
        .catch(error => alert(error.message))

        }


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/')
            }
        })
      
        return unsubscribe
      }, [])
  return (
    <div className='DotSContainer'>
        <MainHeader/>
        <div className='DotSbody'>
            <div className='DotScard'>
                <h1 className='DotH1'>Let’s get you started</h1>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='First Name'
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                     />
                    <input className='DotDotInput' 
                    placeholder='Last Name'
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    />
                </div>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='Vehicle Registration Number' 
                    value={vrn}
                    onChange={(e) => setVrn(e.target.value)}
                    required
                    />
                    <input className='DotDotInput' 
                    placeholder='Vehicle Model'
                    value={vehicleModel}
                    onChange={(e) => setVehicleModel(e.target.value)}
                    required
                     />
                </div>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='DL Number' 
                    value={dlNumber}
                    onChange={(e) => setDlNumber(e.target.value)}
                    required
                    />
                    <input className='DotDotInput' 
                    placeholder='ID Number'
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                    required
                     />
                </div>
                <div className='DotInputs'>
                    <select className='DotDotInput'
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    >
                        <option value="">-- Select Category--</option>
                        <option>Emergency First Aid Responder</option>
                        <option>Emergency Motor Breakdown Services</option>
                        <option>Reliable General Handymen</option>
                        <option>Reliable General Courier/ Package Delivery</option>
                    </select>
                    <input className='DotDotInput' 
                    placeholder='Agent Code' 
                    value={agentcode}
                    onChange={(e) => setAgentCode(e.target.value)}
                    required
                    />
                </div>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='Email' 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                    <input className='DotDotInput' 
                    placeholder='Phone Number'
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    required 
                    />
                </div>
                <div className='DotInputs'>
                    <input className='DotDotInput' 
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    required
                    type='password' 
                    />
                    <input className='DotDotInput' 
                    placeholder='Confirm Password' 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    type='password'
                    />
                </div>
                <button  onClick={handleSignUp} className='DotDotRegister'>Register</button>
            </div>
        </div>
    </div>
  )
}
