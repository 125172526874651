import React, { useEffect, useState } from 'react';
import MainHeader from '../../../Components/Header/Header';
import { auth } from '../../../Database/config';
import { useNavigate } from 'react-router-dom';
import '../Fundi.css';
import { dbm } from '../../../Database/materials';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function Hardware() {

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const collectionRef = dbm.collection('Business');

    collectionRef.get().then((querySnapshot) => {
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
    });
  }, []);

  const renderHeader = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-2">
        <div>
          <h1 className='font-bold text-xl'>
            <span style={{ color: "#FF0000" }}>R-Fundi</span> Hardware ({data.length})
          </h1>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className='flex flex-col'>
      <MainHeader className="mb-20" />
      <div className='flex flex-col gap-4 p-12 pb-20'>
        <DataTable className='text-sm'
          value={data}
          showGridlines
          stripedRows
          paginator
          header={header}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column className='border' field='index' header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1}></Column>
          <Column className='border' header="Name" body={(rowData) => `${rowData.businessname}`}></Column>
          <Column className='border' header="Owner" body={(rowData) => `${rowData.ownersname}`}></Column>
          <Column className='border' header="Category" body={(rowData) => `${rowData.category}`}></Column>
          <Column className='border' header="Phone Number" body={(rowData) => `${rowData.phonenumber}`}></Column>
          <Column className='border' header="Location" body={(rowData) => `${rowData.subcounties}`}></Column>
        </DataTable>
      </div>
    </div>
  );
}
