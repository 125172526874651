import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Screens/Login/Login.js'
import HomeScreen from './Screens/HomeScreen/HomeScreen.js'
import FundiOrder from './Screens/Orders/FundiOrder.js';
import FundiSignup from './Screens/FundiSignup/FundiSignup.jsx';
import FundiSignup2 from './Screens/FundiSignup/FundiSignup2/FundiSignup2.js';
import DotDotsignup from './Screens/DotDotSignUp/DotDotsignup.js';
import Fundi from './Screens/Agents/Fundi.jsx';
import MaterialSignup from './Screens/MaterialSignup/MaterialSignup.js';
import Hardware from './Screens/Agents/Hardware/Hardware.jsx';
import RentalSignup from './Screens/FindRental/FindRental.js';
import Admins from "./Screens/AdminsDetails/Admins.jsx"
import Receipt from './Screens/RecieptGenerator.js/RecieptGenerator.js';
import FundiUpdate from './Screens/FundiUpdate/FundiUpdate.jsx';
import FundiView from './Screens/FundiView/FundiView.jsx';
import Payments from './Screens/Payments/Payments.jsx';
import CreateFundi from './Screens/FundiCreate/FundiCreate.jsx';
import 'primeicons/primeicons.css';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import Clients from './Screens/Agents/Clients.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/fundiorders" element={<FundiOrder />} />
        <Route path="/dotdotsignup" element={<DotDotsignup />} />
        <Route path="/materialsignup" element={<MaterialSignup />} />
        <Route path="/fundi" element={<Fundi />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/hardware" element={<Hardware />} />
        <Route path="/rentalsignup" element={<RentalSignup />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="/fundi/update/:id" element={<FundiUpdate />}></Route>
        <Route path="/fundi/:id" element={<FundiView />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/register-fundi" element={<CreateFundi />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
