// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3SdSGPBFiHHymT2SnH3j5tnBDYwE5z7Y",
    authDomain: "rekebishaadmin.firebaseapp.com",
    projectId: "rekebishaadmin",
    storageBucket: "rekebishaadmin.appspot.com",
    messagingSenderId: "1064100655368",
    appId: "1:1064100655368:web:2eecd4a0bb0b0f27787cbd",
    measurementId: "G-NWZW8S9PS4"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const auth = firebase.auth();
