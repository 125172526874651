import React, { useEffect, useState } from "react";
import MainHeader from "../../Components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../Database/config";
import { dbc } from "../../Database/fundi";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";

export default function FundiView() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    identificationnumber: "",
    email: "",
    profession: "",
    professionalbody: "",
    professionalbodyno: "",
    phonenumber: "",
    goodconductrefno: "",
    profileimage: "",
    countylocation: "",
    subcounty: "",
  });
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const portfoliourl = `https://rekebisha.com/find-fundi/shared/${id}`;

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    const fetchUserData = async () => {
      try {
        const docSnapshot = await dbc.collection("FundiAppUsers").doc(id).get();

        if (docSnapshot.exists) {
          const userData = docSnapshot.data();
          computeCertificationRating(userData);
          computeCompetenceRating(userData);
          computeAverageRating(
            userData.certificationRating,
            userData.competenceRating
          );
          setData(userData);
        } else {
          alert("Error fetching user data! Try again later.");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    fetchUserData();
  }, [id, navigate]);

  const computeCertificationRating = async (userData) => {
    let rating = 0;

    if (userData.cv) {
      rating += 1;
    }

    if (userData.idFront && userData.idBack) {
      rating += 2;
    }

    if (userData.nca) {
      rating += 1;
    }

    if (userData.goodConduct) {
      rating += 1;
    }

    const currentRating = userData.certificationRating;

    if (currentRating != rating) {
      try {
        await dbc.collection("FundiAppUsers").doc(id).update({
          certificationRating: rating,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const computeCompetenceRating = async (userData) => {
    let rating = 0;

    const snapshot = await dbc
      .collection("FundiAppUsers")
      .doc(id)
      .collection("Posts")
      .get();
    const items = snapshot.docs.map((doc) => {
      const data = doc.data();
      const id = doc.id;
      return { id, ...data };
    });

    if (items.length > 10) {
      rating = 2;
    } else if (items.length >= 1) {
      rating = 1;
    } else {
      rating = 0;
    }

    const currentRating = userData.competenceRating;

    if (currentRating != rating) {
      try {
        await dbc.collection("FundiAppUsers").doc(id).update({
          competenceRating: rating,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const computeAverageRating = async (certification, competence) => {
    if (certification != null && competence != null) {
      const overallRating = ((certification + competence) / 10) * 5;
      try {
        await dbc.collection("FundiAppUsers").doc(id).update({
          rating: overallRating,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getCreatedAt = (timestamp) => {
    const dateObject = timestamp.toDate();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  };

  return (
    <div className="bg-white">
      <MainHeader />
      <div className="flex sm:flex-nowrap flex-wrap justify-between py-12 gap-4 sm:px-12 px-2">
        <div className="flex flex-col gap-2">
          {data.profileimage && (
            <img
              src={data.profileimage}
              alt="Profile Image"
              className="w-48 h-auto rounded"
            ></img>
          )}
          {data.subscription != null && (
            <div className="flex flex-col gap-2">
              <h1 className="text-center text-lg font-bold">
                <span className="text-gray-600 uppercase">{`${data.firstname} ${data.lastname}`}</span>
              </h1>
              {data.subscription ? (
                <div className="bg-green-700 uppercase text-xs text-center rounded text-white py-2 font-semibold">
                  Active Subscription
                </div>
              ) : (
                <div className="bg-red-700 uppercase text-xs text-center rounded text-white py-2 font-semibold">
                  Inactive Subscription
                </div>
              )}
              <div className="text-sm text-center text-gray-700">
                Joined:{" "}
                <span className="font-semibold text-red-600">
                  {getCreatedAt(data.createdAt)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="sm:w-5/6 w-full flex flex-col gap-4">
          <div className="flex flex-col items-end gap-4 rounded bg-white border border-gray-200 p-6">
            <div className="grid sm:grid-cols-3 grid-cols-1 w-full justify-start items-center gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">First Name:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  placeholder="First Name"
                  value={data.firstname}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">Last Name:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  placeholder="Last Name"
                  value={data.lastname}
                  disabled
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">ID Number:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.identificationnumber}
                  disabled
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">Email:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.email}
                  disabled
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">Profession:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.profession}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">Profession Body:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.professionalbody}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">
                  Profession Body Registration Number:
                </label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.professionalbodyno}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">
                  Primary Mpesa Phone Number:
                </label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.phonenumber}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">
                  Good Conduct Ref. Number:
                </label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.goodconductrefno}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">Portfolio URL:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={portfoliourl}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">
                  Profile Image URL:
                </label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.profileimage}
                  disabled
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm font-medium">County:</label>
                <input
                  className="p-2 rounded border border-gray-400"
                  value={data.countylocation}
                  disabled
                />
              </div>
              {data.subcounty && (
                <div className="flex flex-col gap-1">
                  <label className="text-sm font-medium">Sub County:</label>
                  <input
                    className="p-2 rounded border border-gray-400"
                    value={data.subcounty}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col w-full pb-12">
            <div className="text-xl font-semibold py-4 uppercase">
              KYC Documents
            </div>
            <div className="flex flex-row items-center gap-2">
              {data.cv && (
                <a
                  href={data.cv}
                  target="_blank"
                  className="bg-gray-50 p-4 hover:bg-blue-50 text-center hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
                >
                  View CV
                </a>
              )}
              {data.idFront && (
                <a
                  href={data.idFront}
                  target="_blank"
                  className="bg-gray-50 p-4 hover:bg-blue-50 text-center hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
                >
                  ID Front
                </a>
              )}
              {data.idBack && (
                <a
                  href={data.idBack}
                  target="_blank"
                  className="bg-gray-50 p-4 hover:bg-blue-50 text-center hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
                >
                  ID Back
                </a>
              )}
              {data.goodConduct && (
                <a
                  href={data.goodConduct}
                  target="_blank"
                  className="bg-gray-50 p-4 hover:bg-blue-50 text-center hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
                >
                  Good Conduct
                </a>
              )}
              {data.nca && (
                <a
                  href={data.nca}
                  target="_blank"
                  className="bg-gray-50 p-4 hover:bg-blue-50 text-center hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
                >
                  NCA Certificate
                </a>
              )}
            </div>
          </div>

          <div className="grid sm:grid-cols-3 grid-cols-1 gap-2">
            <Sidebar
              position="right"
              header="Request Payment"
              visible={isSidebarVisible}
              onHide={() => setIsSidebarVisible(false)}
            >
              <div className="flex flex-col gap-4">
                <h2 className="text-green-600 text-xs font-semibold uppercase">
                  M-Pesa STK Push
                </h2>
                <div className="p-input-icon-left">
                  <i className="pi pi-phone" />
                  <InputText
                    className="w-full pl-8 py-2 border border-gray-800 text-sm"
                    keyfilter="pint"
                    placeholder="Enter Phone Number"
                  />
                </div>
                <button className="bg-black rounded px-2 text-white text-xs py-2">
                  Send STK Push
                </button>
                <div className="border-b border-gray-100"></div>
                <h2 className="text-green-600 text-xs font-semibold uppercase">
                  Verify MPesa Payment
                </h2>
                <div className="p-input-icon-left">
                  <i className="pi pi-info-circle" />
                  <InputText
                    className="w-full pl-8 py-2 border border-gray-800 text-sm"
                    keyfilter="pint"
                    placeholder="Enter Transaction Code"
                  />
                </div>
                <button className="bg-black rounded px-2 text-white text-xs py-2">
                  Verify
                </button>
                <div className="border-b border-gray-100"></div>
                <h2 className="text-green-600 text-xs font-semibold uppercase">
                  M-Pesa Payment Details
                </h2>
                <ul className="text-xs flex flex-col gap-1">
                  <li>Step 1: Go to Lipa Na Mpesa</li>
                  <li>
                    Step 2: PayBill: <span className="font-bold">333000</span>
                  </li>
                  <li>
                    Step 3: Account Number:{" "}
                    <span className="font-bold">ID Number</span>
                  </li>
                  <li>
                    Step 4: Enter Amount:{" "}
                    <span className="font-bold">Ksh. 1,000</span>
                  </li>
                  <li>Step 5: Enter Pin and Checkout</li>
                </ul>
                <button className="bg-black rounded px-2 text-white text-xs py-2">
                  Send SMS Instructions
                </button>
              </div>
            </Sidebar>
            <div
              onClick={() => setIsSidebarVisible(true)}
              className="bg-gray-50 p-4 gap-2 hover:bg-blue-50 hover:shadow-lg border-dashed border border-gray-900 text-black cursor-pointer shadow rounded flex flex-col items-start justify-start"
            >
              <div className="text-xs pb-4 font-semibold uppercase">
                Subscription Details
              </div>
              <div className="flex flex-nowrap items-center gap-2 text-sm">
                <div className="text-gray-600 text-sm">Start:</div>
                <div className="text-black font-semibold">Date Coming Soon</div>
              </div>
              <div className="flex flex-nowrap items-center gap-2 text-sm">
                <div className="text-gray-600 text-sm">End Date:</div>
                <div className="text-black font-semibold">Date Coming Soon</div>
              </div>
            </div>
            <div className="bg-gray-900 sm:py-12 py-4 gap-2 text-white hover:bg-black hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase cursor-pointer shadow rounded flex flex-col items-center justify-center">
              <div className="text-xs">Basic Plan</div>
              <div className="text-xl">Ksh. 1,000/month</div>
            </div>
            <a
              href={portfoliourl}
              target="_blank"
              className="bg-gray-50 sm:py-12 py-4 hover:bg-blue-50 hover:shadow-lg border-dashed border border-gray-900 font-semibold text-sm uppercase text-black cursor-pointer shadow rounded flex justify-center items-center"
            >
              View Portfolio
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
