import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../../Components/Header/Header";
import { auth } from "../../Database/config";
import { useNavigate } from "react-router-dom";
import { dbc } from "../../Database/fundi";
import "./Fundi.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { dbcl } from "../../Database/client";
import { saveAs } from "file-saver";
import Papa from "papaparse";

export default function Clients() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const collectionRef = dbcl.collection("users");
    collectionRef
      .get()
      .then((querySnapshot) => {
        const fetchedData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          fetchedData.push({ id: doc.id, ...data });
        });
        setData(fetchedData);
        setLoading(false);
      });
  }, []);

  const confirm = (id) => {
    confirmDialog({
      message:
        "Are you sure you want to proceed? Once deleted, the record cannot be recovered",
      header: "Delete Record",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      acceptClassName: "bg-red-500 px-2 py-1 text-white font-semibold ml-4",
      rejectClassName: "bg-gray-300 px-2 py-1 text-black font-semibold",
      acceptLabel: "Yes, Delete",
      rejectLabel: "Cancel",
      accept: () => deletePerson(id),
      reject,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Action cancelled",
      detail: "You have not deleted user record",
      life: 3000,
    });
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const showButtons = (rowData) => {
    return (
      <div className="flex flex-row gap-2 items-center justify-between">
        <EyeIcon
          onClick={() => redirectToView(rowData.id)}
          className="w-5 h-5 text-gray-500 cursor-pointer"
        />
        <PencilIcon
          onClick={() => redirectToUpdate(rowData.id)}
          className="w-5 h-5 text-green-500 cursor-pointer"
        />
        <TrashIcon
          onClick={() => confirm(rowData.id)}
          className="w-5 h-5 text-red-500 cursor-pointer"
        />
      </div>
    );
  };

  const getSubscription = (status) => {
    if (status) {
      return (
        <div className="bg-green-700 uppercase text-[10px] text-center rounded text-white py-1 font-semibold">
          Active
        </div>
      );
    }

    return (
      <div className="bg-red-700 uppercase text-[10px] text-center rounded text-white py-1 font-semibold">
        Inactive
      </div>
    );
  };

  const getCreatedAt = (timestamp) => {
    const dateObject = timestamp.toDate();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  };

  const redirectToUpdate = (id) => {
    navigate(`/fundi/update/${id}`);
  };

  const redirectToView = (id) => {
    navigate(`/fundi/${id}`);
  };

  const deletePerson = async (id) => {
    try {
      await dbc.collection("FundiAppUsers").doc(id).update({
        deleted: true,
        deletedBy: auth.currentUser.uid,
      });
      const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData);
      toast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: "You have deleted user successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error occurred. Please try again later.",
        life: 3000,
      });
    }
  };

  const exportCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'clients_data.csv');
  };

  const header = () => {
    return (
      <div className="flex sm:flex-row flex-col items-center justify-between w-full gap-2">
        <div>
          <h1 className="font-bold text-xl">
            <span style={{ color: "#FF0000" }}>Rekebisha</span> Clients ({data.length})
          </h1>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="pl-8 py-2 border border-gray-800 font-semibold"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </div>
          <Button
            type="button"
            className="bg-transparent border border-blue-400 p-2 text-blue-400 hover:border-blue-600 hover:text-blue-600"
            label="Clear Filter"
            outlined
            onClick={clearFilter}
          />
          <Button
            type="button"
            className="bg-green-500 text-white p-2 hover:bg-green-600"
            label="Export CSV"
            onClick={exportCSV}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <Toast ref={toast} position="bottom-left"></Toast>
      <ConfirmDialog />
      <MainHeader />
      <div className="flex flex-col gap-4 sm:p-12 p-2">
        <DataTable
          className="text-sm"
          value={data}
          stripedRows
          paginator
          filters={filters}
          globalFilterFields={[
            "firstname",
            "lastname",
            "phonenumber",
            "profession",
            "countylocation",
          ]}
          header={header}
          rows={10}
          emptyMessage="No agents found."
          loading={loading}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            className="border"
            header="#"
            body={(e, i) => i.rowIndex + 1}
          ></Column>
          <Column
            className="border"
            header="Name"
            body={(rowData) => `${rowData.username} `}
          ></Column>
          <Column
            className="border"
            header="Phone Number"
            body={(rowData) => `${rowData.phonenumber}`}
          ></Column>
          <Column
            className="border"
            header="Email"
            body={(rowData) => `${rowData.email}`}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}
